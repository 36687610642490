import './share-networks.scss';

class ShareNetworks {
    constructor ($element, options) {
        const defaults = {
            initAttr: 'data-sharenetworks',
            copyAttr: 'copy',
            copiedClassname: 'reverse-icon-check',
            defaultClassname: 'reverse-icon-copy',
            stickyAttr: 'data-actionbar-sticky'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$shareNetworks = $element;
        this.$copyButton = this.$shareNetworks.querySelector(`[${this.settings.initAttr}="${this.settings.copyAttr}"]`);

        this.initialize();
    }

    initialize () {
        this.setEvents();
    }

    setEvents () {
        if (this.$copyButton) {
            this.$copyButton.addEventListener('click', (e) => {
                this.addToClipboard(this.$copyButton);
                e.preventDefault();
            });
        }
    }

    addToClipboard ($link) {
        const url = $link.getAttribute('href');
        const linkText = $link.innerText;
        const copiedLinkText = $link.getAttribute('rel');

        if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
            $link.classList.remove(this.settings.defaultClassname);
            $link.classList.add(this.settings.copiedClassname);
            $link.innerText = copiedLinkText;

            // Reset after 3 seconds
            setTimeout(() => {
                $link.classList.remove(this.settings.copiedClassname);
                $link.classList.add(this.settings.defaultClassname);
                $link.innerText = linkText;
            }, 3000);

            return navigator.clipboard.writeText(url);
        }
    }
}

export { ShareNetworks };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$shareNetworks = $context.querySelectorAll('[data-sharenetworks="root"]');
        for (let i = 0; i < $$shareNetworks.length; i++) {
            const $shareNetworks = $$shareNetworks[i];

            const shareNetworksAPI = new ShareNetworks($shareNetworks);
            $shareNetworks.API = shareNetworksAPI;
        }
    }
});
